<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-1">
            <b-row>
              <b-col
                cols="8"
                md="8"
              >
                <h3>{{ modalTitle }}</h3>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                  :columns="columns"
                  :rows="sample"
                  :group-options="{
                    enabled: true,
                    collapsable: true
                  }"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'currentCredentialSampleFile' ">
                      <SampleFileView
                        ref="sampleFileView"
                        :excel-file="props.row.currentCredentialSampleFile"
                        :background-image="certificateBlank[props.row.originalIndex].currentCertificateBlankFile"
                        :current-credential="dataSend"
                        :type-view="0"
                      />
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span
                      v-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-b-modal.credentialConfigSampleFileSaveModal
                          class="btn-icon"
                          variant="primary"
                          size="sm"
                          title="Tải lên file mẫu"
                          @click="onUpdateCredentialConfigSampleFile(props.row)"
                        >
                          <feather-icon icon="UploadIcon" />
                        </b-button>

                      </b-button-group>
                    </span>
                  </template>

                </vue-good-table>
              </b-col>
            </b-row>

          </div>

        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      id="credentialConfigSampleFileSaveModal"
      ref="credentialConfigSampleFileSaveModal"
      ok-only
      ok-title="Xác nhận"
      centered
      size="lg"
      title="Thêm/Sửa mẫu văn bằng/chứng chỉ"
      :hide-footer="true"
    >
      <CredentialSampleConfigFileSave
        :item="currentCredentialSampleFile"
        :current-credential-sample-file-type="currentCertificateBlankFileType"
        @succeed="onSucceed"
      />
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CredentialSampleConfigFileSave from '@/views/credential-sample/CredentialSampleCongfigFileSave.vue'
import SampleFileView from '@/views/credential-view-file/SampleFileView.vue'

export default {
  name: 'SampleCertificateBlank',
  directives: {
    Ripple,
  },
  components: {
    CredentialSampleConfigFileSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    SampleFileView,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
    currentCredentialSampleType: {
      type: Number,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      currentCredentialId: this.dataSend.credentialId,
      modalTitle: `${this.dataSend.name}`,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        credentialId: this.dataSend.credentialId,
      },
      currentCredentialSampleFile: [
        {
          id: 1,
          name: '',
          description: '',
          certificateBlank: '',
          certificateBlankId: 0,
          credentialId: 0,
          status: 1,
          credentialSampleFileCoverId: 0,
          credentialSampleFileMainId: 0,
          credentialSampleFileCover: '',
          credentialSampleFileMain: '',
          fileBlankCoverId: 0,
          fileBlankMainId: 0,
          fileBlankCover: '',
          fileBlankMain: '',
        },
      ],
      currentCredentialSampleFileType: [
        {
          originalIndex: 0,
          currentCredentialSampleFile: '',
        },
      ],

      currentCertificateBlankFile: [
        {
          name: '',
          description: '',
          credentialTypeId: 0,
          type: '',
          number: 0,
          codeStart: '',
          codeEnd: '',
          linkFileCover: '',
          linkFileMain: '',
          fileCoverId: 0,
          fileMainId: 0,
          status: 1,
        },
      ],
      currentCertificateBlankFileType: [
        {
          originalIndex: 0,
          currentCertificateBlankFile: '',
        },
      ],

      sample: [
        {
          type: 'Mặt trước',
          children: [
            { currentCredentialSampleFile: '' },
          ],
        },
        {
          type: 'Mặt sau',
          children: [
            { currentCredentialSampleFile: '' },
          ],
        },
      ],
      certificateBlank: [
        {
          currentCertificateBlankFile: '',
          currentCertificateBlankFileName: '',
        },
        {
          currentCertificateBlankFile: '',
          currentCertificateBlankFileName: '',
        },
      ],
      columns: [
        {
          label: '',
          field: 'type',
          width: '8%',
        },
        {
          label: '',
          field: 'currentCredentialSampleFile',
          width: '85%',
        },
        {
          label: '',
          field: 'action',
          width: '5%',

        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'certificateBlank/statuses',
      certificateBlankFiles: 'certificateBlank/certificateBlankFiles',
      credentialSamples: 'credentialSample/credentialSamples',
    }),
  },
  watch: {
    credentialSamples: {
      immediate: true,
      handler(newValue, oldValue) {
        this.sample = this.generateSample(newValue[this.currentCredentialSampleType])
        this.certificateBlank = this.generateCertificateBlank(newValue[this.currentCredentialSampleType])
      },
    },
  },
  async created() {
    await this.readCredentialSamples(this.filter)
    this.currentCredentialSampleFile = { ...this.dataSend }
    this.currentCertificateBlankId = this.dataSend.credentialId
    const {
      fileBlankCoverId, fileBlankMainId, fileBlankCover, fileBlankMain, credentialSampleFileCoverId, credentialSampleFileMainId, credentialSampleFileCover, credentialSampleFileMain, credentialSampleFileCoverName, credentialSampleFileMainName,
    } = this.credentialSamples[0]
    this.currentCredentialSampleFile = {
      ...this.currentCredentialSampleFile,
      fileBlankCoverId,
      fileBlankMainId,
      fileBlankCover,
      fileBlankMain,
      credentialSampleFileCoverId,
      credentialSampleFileMainId,
      credentialSampleFileCover,
      credentialSampleFileMain,
      credentialSampleFileCoverName,
      credentialSampleFileMainName,
    }
  },
  methods: {
    ...mapActions({
      readCertificateBlankFiles: 'certificateBlank/readCertificateBlankFiles',
      updateCertificateBlank: 'certificateBlank/updateCertificateBlank',
      readCredentialSamples: 'credentialSample/readCredentialSamples',
    }),
    print() {
      window.print()
    },
    generateSample(credentialSamples) {
      const sample = [
        {
          type: 'Mặt 1',
          children: [],
        },
        {
          type: 'Mặt 2',
          children: [],
        },
      ]
      sample[0].children.push({
        currentCredentialSampleFile: credentialSamples.credentialSampleFileCover || '',
        currentCredentialSampleFileName: credentialSamples.credentialSampleFileCoverName || '',
      })
      sample[1].children.push({
        currentCredentialSampleFile: credentialSamples.credentialSampleFileMain || '',
        currentCredentialSampleFileName: credentialSamples.credentialSampleFileMainName || '',
      })
      return sample
    },
    generateCertificateBlank(credentialSamples) {
      const certificateBlank = []
      certificateBlank.push({ currentCertificateBlankFile: credentialSamples.fileBlankCover || '' })
      certificateBlank.push({ currentCertificateBlankFile: credentialSamples.fileBlankMain || '' })

      return certificateBlank
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.readCredentialSamples(this.filter)
        const {
          fileBlankCoverId, fileBlankMainId, fileBlankCover, fileBlankMain, credentialSampleFileCoverId, credentialSampleFileMainId, credentialSampleFileCover, credentialSampleFileMain, credentialSampleFileCoverName, credentialSampleFileMainName,
        } = this.credentialSamples[0]
        this.currentCredentialSampleFile = {
          ...this.currentCredentialSampleFile,
          fileBlankCoverId,
          fileBlankMainId,
          fileBlankCover,
          fileBlankMain,
          credentialSampleFileCoverId,
          credentialSampleFileMainId,
          credentialSampleFileCover,
          credentialSampleFileMain,
          credentialSampleFileCoverName,
          credentialSampleFileMainName,
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
      const {
        fileBlankCoverId, fileBlankMainId, fileBlankCover, fileBlankMain, credentialSampleFileCoverId, credentialSampleFileMainId, credentialSampleFileCover, credentialSampleFileMain, credentialSampleFileCoverName, credentialSampleFileMainName,
      } = this.credentialSamples[0]
      this.currentCredentialSampleFile = {
        ...this.currentCredentialSampleFile,
        fileBlankCoverId,
        fileBlankMainId,
        fileBlankCover,
        fileBlankMain,
        credentialSampleFileCoverId,
        credentialSampleFileMainId,
        credentialSampleFileCover,
        credentialSampleFileMain,
        credentialSampleFileCoverName,
        credentialSampleFileMainName,
      }
    },
    onUpdateCredentialConfigSampleFile(row) {
      this.currentCertificateBlankFileType = { ...row }
    },
  },
}
</script>
<style scoped lang="scss">

</style>
